body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;

  .container {
    margin: 24px;
    overflow-y: auto;
  }
}

.ant-table-pagination.ant-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  .ant-pagination-options {
    display: flex;
    .ant-pagination-options-quick-jumper {
      display: flex;
      width: 210px;
    }
  }
}

.ant-time-picker,
.ant-select,
.ant-input-number,
.ant-calendar-picker,
.ant-calendar-picker-input {
  width: 100% !important;
}

.fadeInTop {
  animation: animatename 0.6s ease-in-out;
}

@keyframes animatename {
  0% {
    transform: scale3d(0.1, 0.1, 0.2);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@primary-color: #8A2BE2;@link-color: #8A2BE2;